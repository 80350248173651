<template>
  <div>
    <defaulttop :active="articleId"></defaulttop>
    <div class="swipertop flex flex-space">
      <div class="coh1-1 flex flex-centers">
        <img
          v-if="type == 2"
          src="../assets/servicesbg1.png"
          class="aboutbg1img1"
        />
        <img
          v-if="type == 3"
          src="../assets/innovationbg1.png"
          class="aboutbg1img"
        />
        <img
          v-if="type == 4"
          src="../assets/centerbg1.png"
          class="aboutbg1img1"
        />
      </div>
      <div class="coh1-1 flex flex-bottom">
        <img
          v-if="type == 2"
          src="../assets/servicesbg2.png"
          class="aboutbg2img2"
        />
        <img
          v-if="type == 3"
          src="../assets/innovationbg2.png"
          class="aboutbg2img"
        />
        <img
          v-if="type == 4"
          src="../assets/centerbg2.png"
          class="aboutbg2img1"
        />
      </div>
    </div>
    <div class="context">
      <div class="flex flex-centers">
        <span class="txt1 hovera" @click="goback">
          {{ type == 2 ? "生态服务能力" : type == 3 ? "应用创新" : "中心动态" }}
        </span>
        <img src="../assets/arrow.png" class="arrowright" />
        <span class="txt2" v-text="(articleInfo.className ? `【${articleInfo.className}】` : ``) + articleInfo.title">
        </span>
      </div>
      <div class="vhtml">
        <div v-html="articleInfo.context" class="rich"></div>
        <div class="flex flex-end mt60">
          <div class="back hovera" @click="goback">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaulttop from "../components/defaulttop";
export default {
  name: "Details",
  data() {
    return {
      articleInfo: {
        className:'',
        title: ''
      },
      type: 2, //2.生态服务能力 3.应用创新 4.中心动态
      articleId: 0
    };
  },
  components: {
    defaulttop,
  },
  created() {
    this.type = this.$route.query.type;
    this.articleId = this.$route.query.articleId;
    this.getData();
  },
  mounted() {

  },
  methods: {
    getData(){
      this.$http.getArticleInfo(this.articleId).then(res=>{
        if(res.code == 0){
          this.articleInfo = res.data;
        }
      })
    },
    // 返回
    goback() {
      let _path = '';
      if(this.type == 2) _path = '/services';
      if(this.type == 3) _path = '/innovation';
      if(this.type == 4) _path = '/centralnews';
      this.$router.push({
        path: _path,
        query:{
          classid: this.$route.query.classid
        }
      })
    },
  },
};
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}

.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 70px 80px;
  background: #fff;
  margin-top: 50px;
}
.rich {
  
}
.rich >>> img {
  max-width: 100%;
}
.back {
  width: 107px;
  height: 43px;
  border: 1px solid #1a5df1;
  line-height: 43px;
  text-align: center;
  font-size: 18px;
  color: #1a5df1;
}
</style>
